import * as React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import classNames from 'classnames';

import { formatDate } from '../utils/date';
import { formatPermalink } from '../utils/permalink';
import { unescape } from '../utils/htmlEntities';
import defaultImage from '../img/blank.jpg';

type Props = {
  posts: any[];
  title?: string;
};

const IndexPage = ({ posts, title }: Props) => {

  const columns = [];
  let cards = [];

  const renderItem = (post, i) => {
    const title = unescape(post.title);
    const titleClass = classNames('title', 'is-size-6-touch', i < 2 ? 'is-size-4-desktop' : 'is-size-5-desktop');
    return (
      <Column
        className="column"
        key={post.id}
      >
        <Link to={formatPermalink(post.slug, post.date)}>
          <div className="card">
            <div className="card-image">
              <figure className="image is-16by9">
                {post.featured_media && <EyeCatch className="eye-catch" image={post.featured_media.source_url} title={title} />}
                {!post.featured_media && <EyeCatch className="eye-catch" image={defaultImage} title={title} />}
              </figure>
            </div>
            <div className="card-content">
              <div className="content">
                <p className={titleClass}>{title}</p>
                <time dateTime={post.date}>{formatDate(post.date)}</time>
              </div>
            </div>
          </div>
        </Link>
      </Column>
    );
  };

  posts.forEach(({ node }, i) => {
    cards.push(renderItem(node, i));
    if (i === 1) {
      columns.push(<div className="columns" key={i}>{cards}</div>);
      cards = [];
    } else if ((i + 2) % 3 === 0) {
      columns.push(<div className="columns" key={i}>{cards}</div>);
      cards = [];
    } else if (i === posts.length - 1 && cards.length) {
      if (cards.length < 3) {
        // 空のBoxで埋める
        cards = cards.concat(
          Array.from({ length: 3 - cards.length })
            .fill(<Column className="column" key={`${i}${Math.floor(Math.random() * 1000)}`} />)
        );
      }
      columns.push(<div className="columns" key={i}>{cards}</div>);
    }
  });


  return (
    <section className="section">
      <Container>
        <Wrapper>
          <h1 className="has-text-weight-bold is-size-2">{title}</h1>
          {columns}
        </Wrapper>
      </Container>
    </section>
  )
};

export default IndexPage;


export const pageQuery = graphql`
  fragment PostListFields on wordpress__POST {
    id
    title
    excerpt
    featured_media {
      source_url
    }
    author {
      name
      slug
      avatar_urls {
        wordpress_48
      }
    }
    date
    slug
  }
`

const Container = styled.div`
  position: relative;
  margin: 0 auto;
`;

const Column = styled.div`
  
  a {
    display: block;
    height: 100%;
  }
  
  .card {
    height: 100%;
  }
  
  .title {
    line-height: 1.5;
  }
  
  time {
    font-size: 0.9em;
  }
`;

const EyeCatch = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  background-image: url(${({ image }) => image});
`;


const Wrapper = styled.div`
  max-width: 960px;
  margin: 0 auto;
`;
