import * as React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

type Props = {
  name: string;
  description: string;
  // avatar_urls: {
  //   wordpress_96: string;
  // };
};

const AuthorInfo = ({ data }: Props) => {
  return (
    <Container>
      <img src={data.avatar_urls.wordpress_96} alt={data.name} />
      <div>
        <h3>{data.name}</h3>
        <p>{data.description}</p>
        <Meta>
          <Link to="/about">プロフィール</Link>
        </Meta>
      </div>
    </Container>
  );
};

export default AuthorInfo;

const Container = styled.div`
  display: flex;
  padding: 5px;
  margin-top: 3em;
  margin-bottom: 2em;
  
  > img {
    flex: 0 0 auto;
    margin-right: 20px;
    width: 96px;
    height: 96px;
    border-radius: 50%;
  }
  
  h3 {
    margin-bottom: 10px;
    font-size: 1.2em;
    font-weight: bold;
  }
`;

const Meta = styled.p`
  margin-top: 1em;
`;
